import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from  'vuex-router-sync'
import moment from 'moment'
import axios from 'axios'
import './register-sw'

export function createApp() {

	const router = createRouter()
	const store = createStore()
	sync(store, router)

	// filters
	Vue.filter('getListDate', function(value) {
		if (value) {
			return moment(String(value)).format('MMM YYYY')
		}
	})

	Vue.filter('getDay', function(value) {
		if (value) {
			return moment(String(value)).format('DD')
		}
	})

	Vue.filter('convertUnixShort', function(value) {
		if (value) {
			return moment.unix(value).format('HH:mm')
		}
	})

	Vue.filter('convertUnixLong', function(value) {
		if (value) {
			return moment.unix(value).format('DD MMM')
		}
	})

	// mixins
	Vue.mixin ({
		methods: {
			get_variable: async function () {
				var result = null, tmp = []
				window.location.search
					.substr(1)
					.split("&")
					.forEach(function (item) {
					  tmp = item.split("=")
					  if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
					})
				return result
			}, 
			fetch_listings: async function () {
				await axios
				.get('https://luxury-dev.chaseveritt.co.za/api/fetch_listings.php',
				{
					headers: {
						'Accept': 'application/json',
					}
				})
				.then(response => (store.state.listings = response.data))
				.catch(error => (console.log(error)))
			},
			formatCurrency(value) {
				return value.toLocaleString('en-US', {
					style: 'currency',
					currency: 'ZAR',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				})
			}
		}
	})

	Vue.config.productionTip = false
	Vue.config.devtools = false

	console.log("Build: " + store.state.build + " :: V" + store.state.version)
	console.log("Mode: Web")
	store.state.platform = 'web'
	store.state.pp = 'fcm'

	// app setup
	const app = new Vue({
		router,
		store,
		render: h => h(App),
	})

	return { app, router, store }

}